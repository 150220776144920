export default async function ({ app, store, redirect }) {
  let config = store.state.config;
  if (!config) {
    config = await store.dispatch('getConfig');
  }
  const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD

  if (config && config.preindexLink && app.$cookies.get('date') !== date) {
    app.$cookies.set('date', date);
    return redirect(config.preindexLink);
  }
}
