/*
 * image preloader
 */

const { CDN } = process.env;

const pcImages = [
  `${CDN}/feature/feature_story_1_hover.png`,
  `${CDN}/feature/feature_story_2_hover.png`,
  `${CDN}/feature/feature_story_3_hover.png`,
  `${CDN}/feature/feature_story_4_hover.png`,
  `${CDN}/feature/feature_story_5_hover.png`,
  `${CDN}/feature/feature_story_6_hover.png`,
  `${CDN}/class/classes_character_1.png`,
  `${CDN}/class/classes_character_2.png`,
  `${CDN}/class/classes_character_3.png`,
  `${CDN}/class/classes_character_4.png`,
  `${CDN}/class/classes_character_5.png`,
  `${CDN}/class/classes_character_6.png`,
];

const mobileImages = [
  `${CDN}/feature/feature_story_1_hover.png`,
  `${CDN}/feature/feature_story_2_hover.png`,
  `${CDN}/feature/feature_story_3_hover.png`,
  `${CDN}/feature/feature_story_4_hover.png`,
  `${CDN}/feature/feature_story_5_hover.png`,
  `${CDN}/feature/feature_story_6_hover.png`,
  `${CDN}/class/classes_character_1_m.png`,
  `${CDN}/class/classes_character_2_m.png`,
  `${CDN}/class/classes_character_3_m.png`,
  `${CDN}/class/classes_character_4_m.png`,
  `${CDN}/class/classes_character_5_m.png`,
  `${CDN}/class/classes_character_6_m.png`,
];

export default function () {
  if (window.innerWidth > 750) {
    pcImages.forEach((image) => {
      new Image().src = image;
    });
  } else {
    mobileImages.forEach((image) => {
      new Image().src = image;
    });
  }
}
