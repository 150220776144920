import constants from '~/constants';

export const state = () => ({
  nav: null,
  config: null,
  features: null,
  tutorials: null,
  classes: null,
  guides: null,
  guideDetail: null,
  news: null,
  newsDetail: null,
  story: null,
  scenes: null,

  isLoading: true,
  isShowFirstEnterLoading: true,
  isMobile: false,
  isBgvStopAtFirst: false,
  currentModal: null,
});

const UPDATE_NAV = 'UPDATE_NAV';
const UPDATE_CONFIG = 'UPDATE_CONFIG';
const UPDATE_FEATURES = 'UPDATE_FEATURES';
const UPDATE_TUTORIALS = 'UPDATE_TUTORIALS';
const UPDATE_CLASSES = 'UPDATE_CLASSES';
const UPDATE_GUIDES = 'UPDATE_GUIDES';
const UPDATE_GUIDE_DETAIL = 'UPDATE_GUIDE_DETAIL';
const UPDATE_NEWS = 'UPDATE_NEWS';
const UPDATE_NEWS_DETAIL = 'UPDATE_NEWS_DETAIL';
const UPDATE_STORY = 'UPDATE_STORY';
const UPDATE_SCENES = 'UPDATE_SCENES';

const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';
const UPDATE_IS_SHOW_FIRST_ENTER_LOADING = 'UPDATE_IS_SHOW_FIRST_ENTER_LOADING';
const UPDATE_IS_MOBILE = 'UPDATE_IS_MOBILE';
const UPDATE_IS_BGV_STOP_AT_FIRST = 'UPDATE_IS_BGV_STOP_AT_FIRST';

const UPDATE_CURRENT_MODAL = 'UPDATE_CURRENT_MODAL';

async function apiHandler(apiFunction, ...args) {
  try {
    const response = await apiFunction(...args);

    if (!response.error) {
      return response;
    }
  } catch (err) {
    this.app.$sentry.captureException(err);
  }
}

export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch('getNav');
  },
  async getNav({ commit }) {
    const response = await apiHandler.call(this, this.$services.getNav);

    if (response) {
      commit(UPDATE_NAV, response);
    }
    return response;
  },
  async getConfig({ commit }) {
    const response = await apiHandler.call(this, this.$services.getConfig);

    if (response) {
      commit(UPDATE_CONFIG, response);
    }
    return response;
  },
  async getFeatures({ commit }) {
    const response = await apiHandler.call(this, this.$services.getFeatures);

    if (response) {
      commit(UPDATE_FEATURES, response);
    }
    return response;
  },
  async getTutorials({ commit }) {
    const response = await apiHandler.call(this, this.$services.getTutorials);

    if (response) {
      commit(UPDATE_TUTORIALS, response);
    }
    return response;
  },
  async getClasses({ commit }) {
    const response = await apiHandler.call(this, this.$services.getClasses);

    if (response) {
      commit(UPDATE_CLASSES, response);
    }
    return response;
  },
  async getGuides(
    { commit },
    {
      category = constants.GUIDES_CATEGORY_ID__ALL,
      page = 1,
      withTop = constants.WITH_TOP,
    },
  ) {
    commit(UPDATE_IS_LOADING, true);
    const response = await apiHandler.call(this, this.$services.getGuides, {
      category,
      page,
      withTop,
    });

    if (response) {
      commit(UPDATE_IS_LOADING, false);
      commit(UPDATE_GUIDES, response);
    }
    return response;
  },
  async getGuideDetail({ commit }, guideId) {
    const response = await apiHandler.call(
      this,
      this.$services.getGuideDetail,
      {
        guideId,
      },
    );

    if (response) {
      commit(UPDATE_GUIDE_DETAIL, response);
    }
    return response;
  },
  async getNews(
    { commit },
    {
      category = constants.NEWS_CATEGORY_ID__ALL,
      page = 1,
      withTop = constants.WITH_TOP,
    },
  ) {
    commit(UPDATE_IS_LOADING, true);
    const response = await apiHandler.call(this, this.$services.getNews, {
      category,
      page,
      withTop,
    });

    if (response) {
      commit(UPDATE_IS_LOADING, false);
      commit(UPDATE_NEWS, response);
    }
    return response;
  },
  async getNewsDetail({ commit }, newsId) {
    const response = await apiHandler.call(this, this.$services.getNewsDetail, {
      newsId,
    });

    if (response) {
      commit(UPDATE_NEWS_DETAIL, response);
    }
    return response;
  },
  async getStory({ commit }, chapterId) {
    const response = await apiHandler.call(this, this.$services.getStory, {
      chapterId,
    });

    if (response) {
      commit(UPDATE_STORY, response);
    }
    return response;
  },
  async getScenes({ commit }) {
    const response = await apiHandler.call(this, this.$services.getScenes);

    if (response) {
      commit(UPDATE_SCENES, response);
    }
    return response;
  },
  updateIsShowFirstEnterLoading({ commit }, isShowFirstEnterLoading) {
    commit(UPDATE_IS_SHOW_FIRST_ENTER_LOADING, isShowFirstEnterLoading);
  },
  updateIsMobile({ commit }, isMobile) {
    commit(UPDATE_IS_MOBILE, isMobile);
  },
  updateIsBgvStopAtFirst({ commit }, isBgvStopAtFirst) {
    commit(UPDATE_IS_BGV_STOP_AT_FIRST, isBgvStopAtFirst);
  },
  setCurrentModal({ commit }, currentModal) {
    commit(UPDATE_CURRENT_MODAL, currentModal);
  },
};

export const mutations = {
  [UPDATE_NAV](state, nav) {
    state.nav = nav;
  },
  [UPDATE_CONFIG](state, config) {
    state.config = config;
  },
  [UPDATE_FEATURES](state, features) {
    state.features = features;
  },
  [UPDATE_TUTORIALS](state, tutorials) {
    state.tutorials = tutorials;
  },
  [UPDATE_CLASSES](state, classes) {
    state.classes = classes;
  },
  [UPDATE_GUIDES](state, guides) {
    state.guides = {
      ...state.guides,
      ...guides,
    };
  },
  [UPDATE_GUIDE_DETAIL](state, guideDetail) {
    state.guideDetail = guideDetail;
  },
  [UPDATE_NEWS](state, news) {
    state.news = {
      ...state.news,
      ...news,
    };
  },
  [UPDATE_NEWS_DETAIL](state, newsDetail) {
    state.newsDetail = newsDetail;
  },
  [UPDATE_STORY](state, story) {
    state.story = story;
  },
  [UPDATE_SCENES](state, scenes) {
    state.scenes = scenes;
  },
  [UPDATE_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [UPDATE_IS_SHOW_FIRST_ENTER_LOADING](state, isShowFirstEnterLoading) {
    state.isShowFirstEnterLoading = isShowFirstEnterLoading;
  },
  [UPDATE_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile;
  },
  [UPDATE_IS_BGV_STOP_AT_FIRST](state, isBgvStopAtFirst) {
    state.isBgvStopAtFirst = isBgvStopAtFirst;
  },
  [UPDATE_CURRENT_MODAL](state, currentModal) {
    state.currentModal = currentModal;
  },
};

export const getters = {
  guideCategoryName: (state) => (categoryId) => {
    return (
      state.guides?.categories.find((category) => category.id === categoryId)
        ?.name || ''
    );
  },
  newsCategoryName: (state) => (categoryId) => {
    return (
      state.news?.categories.find((category) => category.id === categoryId)
        ?.name || ''
    );
  },
  imgSuffix: (state) => (state.isMobile ? '_m' : ''),
};
