//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'Relationship',

  data() {
    return {
      CDN: process.env.CDN,
    };
  },

  methods: {
    ...mapActions(['setCurrentModal']),
  },
};
