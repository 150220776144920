import utils from '@/utils';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const mock = require('./mock').default;
  mock();
}

export default ({ $axios }, inject) => {
  const instance = $axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      utils.camelizeKeys(response.data),
    (error) =>
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      Promise.reject(error),
  );

  const api = (method, url, data = null) => {
    const requestType = method.toLowerCase();
    const normalizedParams = utils.snakifyKeys(data);
    switch (requestType) {
      case 'get':
        return instance.get(url, { params: normalizedParams });
      case 'put':
        return instance.put(url, normalizedParams);
      case 'delete':
        return instance.delete(url, { params: normalizedParams });
      case 'post':
        return instance.post(url, normalizedParams);
      default:
        throw new Error(
          `Unknown request method of ${method}! You might have a typo.`,
        );
    }
  };

  inject('services', {
    getNav() {
      return api('get', '/api/nav');
    },
    getConfig() {
      return api('get', '/api/config');
    },
    getNews({ category, page, withTop }) {
      return api('get', '/api/news', { category, page, withTop });
    },
    getNewsDetail({ newsId }) {
      return api('get', `/api/news/${newsId}`);
    },
    getClasses() {
      return api('get', '/api/classes');
    },
    getGuides({ category, page, withTop }) {
      return api('get', '/api/guides', { category, page, withTop });
    },
    getGuideDetail({ guideId }) {
      return api('get', `/api/guide/${guideId}`);
    },
    getFeatures() {
      return api('get', '/api/features');
    },
    getTutorials() {
      return api('get', '/api/tutorials');
    },
    getStory({ chapterId }) {
      return api('get', `/api/story/${chapterId}`);
    },
    getScenes() {
      return api('get', '/api/scenes');
    },
  });
};
