//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'FirstEnterLoading',

  data() {
    return {
      CDN: process.env.CDN,
      REGION: process.env.REGION,
    };
  },

  computed: {
    ...mapState(['isShowFirstEnterLoading']),
  },
};
