//
//
//
//
//
//

export default {
  name: 'Error',

  data() {
    return {
      CDN: process.env.CDN,
    };
  },
};
