export default Object.freeze({
  URL_TYPE__INTERNAL: 'URL_TYPE__INTERNAL',
  URL_TYPE__EXTERNAL: 'URL_TYPE__EXTERNAL',
  URL_TYPE__UNSET: 'URL_TYPE__UNSET',
  GUIDES_CATEGORY_ID__ALL: 0,
  GUIDES_CATEGORY_ID__SYSTEM: 1,
  GUIDES_CATEGORY_ID__BATTLE: 2,
  GUIDES_CATEGORY_ID__CAREER: 3,
  GUIDES_CATEGORY_ID__OTHERS: 4,
  STATUS__COMING_SOON: 0,
  STATUS__OPEN: 1,
  WITHOUT_TOP: 0,
  WITH_TOP: 1,
  TUTORIAL_TYPE__CROSS: 1,
  TUTORIAL_TYPE__HTML: 2,
  NEWS_CATEGORY_ID__ALL: 0,
  NEWS_CATEGORY_ID__EVENT: 1,
  NEWS_CATEGORY_ID__SYSTEM: 2,
  NEWS_CATEGORY_ID__OTHERS: 3,
  SCENE__TAB__IMAGES: 0,
  SCENE__TAB__VIDEOS: 1,
  MODAL__TRAILER: 'trailer',
  MODAL__RELATIONSHIP: 'relationship',
});
