import constants from '@/constants';

function getParam(paramName, url) {
  const href = url || window.location.href;
  const name = paramName.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(href);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function camelizeStr(str) {
  return str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());
}

function snakifyStr(str) {
  return str.replace(/(?:^|\.?)([A-Z])/g, (_, x) => `_${x.toLowerCase()}`);
}

function convertCase(convertFunc) {
  function converter(thing) {
    if (Array.isArray(thing)) {
      return thing.map((i) => converter(i));
    }
    if (
      thing !== null &&
      typeof thing === 'object' &&
      !(thing instanceof Object)
    ) {
      // eslint-disable-next-line no-param-reassign
      thing = { ...thing };
    }
    if (thing instanceof Object) {
      const newObj = {};
      Object.keys(thing).forEach((k) => {
        newObj[convertFunc(k)] = converter(thing[k]);
      });
      return newObj;
    }
    return thing;
  }
  return converter;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const linkType = (link) => {
  let linkType = '';
  const isExternalUrl = new RegExp('^(http|https)://');

  if (link === '') {
    linkType = constants.URL_TYPE__UNSET;
  } else if (isExternalUrl.test(link)) {
    linkType = constants.URL_TYPE__EXTERNAL;
  } else {
    linkType = constants.URL_TYPE__INTERNAL;
  }

  return linkType;
};

const calcRatio = () => {
  const designSafeWidth = window.innerWidth <= 750 ? 750 : 1920;
  const adjustFontSize = (window.innerWidth / designSafeWidth) * 100;

  return Math.min(adjustFontSize, 100) / 100;
};

export default {
  getParam,
  camelizeStr,
  snakifyStr,
  delay,
  linkType,
  calcRatio,
  camelizeKeys: convertCase(camelizeStr),
  snakifyKeys: convertCase(snakifyStr),
};
