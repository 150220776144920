import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bb6f3bae = () => interopDefault(import('../../pages/character/index.vue' /* webpackChunkName: "pages/character/index" */))
const _11f796b3 = () => interopDefault(import('../../pages/feature.vue' /* webpackChunkName: "pages/feature" */))
const _344ad0bc = () => interopDefault(import('../../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _a2f2782e = () => interopDefault(import('../../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _29843e89 = () => interopDefault(import('../../pages/scene.vue' /* webpackChunkName: "pages/scene" */))
const _2d5d2b75 = () => interopDefault(import('../../pages/story/index.vue' /* webpackChunkName: "pages/story/index" */))
const _0bd4af71 = () => interopDefault(import('../../pages/tutorial.vue' /* webpackChunkName: "pages/tutorial" */))
const _eac36044 = () => interopDefault(import('../../pages/character/_characterId.vue' /* webpackChunkName: "pages/character/_characterId" */))
const _6b27eafe = () => interopDefault(import('../../pages/guide/_guideId.vue' /* webpackChunkName: "pages/guide/_guideId" */))
const _5d861a44 = () => interopDefault(import('../../pages/news/_newsId.vue' /* webpackChunkName: "pages/news/_newsId" */))
const _816ca694 = () => interopDefault(import('../../pages/story/_chapterId.vue' /* webpackChunkName: "pages/story/_chapterId" */))
const _db628862 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/character",
    component: _bb6f3bae,
    name: "character"
  }, {
    path: "/feature",
    component: _11f796b3,
    name: "feature"
  }, {
    path: "/guide",
    component: _344ad0bc,
    name: "guide"
  }, {
    path: "/news",
    component: _a2f2782e,
    name: "news"
  }, {
    path: "/scene",
    component: _29843e89,
    name: "scene"
  }, {
    path: "/story",
    component: _2d5d2b75,
    name: "story"
  }, {
    path: "/tutorial",
    component: _0bd4af71,
    name: "tutorial"
  }, {
    path: "/character/:characterId",
    component: _eac36044,
    name: "character-characterId"
  }, {
    path: "/guide/:guideId",
    component: _6b27eafe,
    name: "guide-guideId"
  }, {
    path: "/news/:newsId",
    component: _5d861a44,
    name: "news-newsId"
  }, {
    path: "/story/:chapterId",
    component: _816ca694,
    name: "story-chapterId"
  }, {
    path: "/",
    component: _db628862,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
