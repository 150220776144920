//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  name: 'Trailer',

  computed: {
    ...mapState(['config']),
  },

  methods: {
    ...mapActions(['setCurrentModal']),
  },
};
