//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constants from '~/constants';
import utils from '~/utils';

export default {
  name: 'BottomNav',

  data() {
    return {
      constants,
    };
  },

  computed: {
    ...mapState(['nav']),
  },

  methods: {
    checkLinkType(link) {
      return utils.linkType(link);
    },
  },
};
