var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'top-nav',
    {
      fixed: _vm.$device.isMobile || _vm.isFixed,
      hover: _vm.isHover,
    } ],on:{"mouseenter":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[(_vm.nav)?[_c('transition',{attrs:{"name":"sub-nav-section"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHover),expression:"isHover"}],staticClass:"sub-nav-section"},[_c('div',{staticClass:"menu"},_vm._l((_vm.nav.pc),function(mainNav,i){return _c('ul',{key:i,staticClass:"column"},_vm._l((mainNav.children),function(subNav,j){return _c('li',{key:j,staticClass:"sub-nav"},[(
                  _vm.checkLinkType(subNav.link) === _vm.constants.URL_TYPE__EXTERNAL
                )?_c('a',{staticClass:"sub-link",attrs:{"href":subNav.link ? subNav.link : '/',"target":subNav.isBlank ? '_blank' : '_self',"aria-label":"link"},on:{"click":function($event){return _vm.handleExternalLinkClick(subNav)}}},[_vm._v("\n                "+_vm._s(subNav.title)+"\n              ")]):(
                  _vm.checkLinkType(subNav.link) === _vm.constants.URL_TYPE__INTERNAL
                )?_c('nuxt-link',{staticClass:"sub-link",attrs:{"to":subNav.link ? subNav.link : '/',"target":subNav.isBlank ? '_blank' : '_self',"aria-label":"link"}},[_vm._v("\n                "+_vm._s(subNav.title)+"\n              ")]):_c('p',{staticClass:"sub-link"},[_vm._v("\n                "+_vm._s(subNav.title)+"\n              ")])],1)}),0)}),0)])])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"main-nav-section"},[_c('nuxt-link',{staticClass:"home",attrs:{"to":"/","aria-label":"home"}},[_c('img',{staticClass:"icon",attrs:{"src":(_vm.CDN + "/appicon.png"),"alt":"app icon"}}),_vm._v(" "),_c('img',{staticClass:"info",attrs:{"src":(_vm.CDN + "/appicon_info.svg"),"alt":"app info"}})]),_vm._v(" "),(_vm.nav)?_c('ul',{staticClass:"pc-menu"},[_vm._l((_vm.nav.pc),function(nav,index){return _c('li',{key:index,staticClass:"pc-nav"},[(
            _vm.checkLinkType(nav.title.link) === _vm.constants.URL_TYPE__EXTERNAL
          )?_c('a',{staticClass:"link",attrs:{"href":nav.title.link ? nav.title.link : '/',"target":nav.title.isBlank ? '_blank' : '_self',"aria-label":"link"}},[_c('div',{staticClass:"bg"}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(nav.title.main))]),_vm._v(" "),_c('p',{staticClass:"sub"},[_vm._v(_vm._s(nav.title.sub))])])]):(
            _vm.checkLinkType(nav.title.link) === _vm.constants.URL_TYPE__INTERNAL
          )?_c('nuxt-link',{staticClass:"link",attrs:{"to":nav.title.link ? nav.title.link : '/',"target":nav.title.isBlank ? '_blank' : '_self',"aria-label":"link"}},[_c('div',{staticClass:"bg"}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(nav.title.main))]),_vm._v(" "),_c('p',{staticClass:"sub"},[_vm._v(_vm._s(nav.title.sub))])])]):_c('div',{staticClass:"link"},[_c('div',{staticClass:"bg"}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(nav.title.main))]),_vm._v(" "),_c('p',{staticClass:"sub"},[_vm._v(_vm._s(nav.title.sub))])])])],1)}),_vm._v(" "),(_vm.REGION === 'th')?_c('li',{staticClass:"th-wiki-link"},[_c('a',{attrs:{"href":"https://guide.mbm.garena.in.th","target":"_blank","aria-label":"wiki link"}})]):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.nav && _vm.nav.gameDownloadLinks)?_c('a',{staticClass:"link-download",attrs:{"href":_vm.nav.gameDownloadLinks,"target":"_blank","aria-label":"download link"}},[_c('img',{staticClass:"text-download",attrs:{"src":(_vm.CDN + "/appicon_download.svg"),"alt":"download"}})]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }