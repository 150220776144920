//
//
//
//
//
//

export default {
  name: 'TopBar',

  mounted() {
    window.sitebar.create();
  },
};
