import axios from 'axios';
import message from './message';

const langIdMap = JSON.parse(process.env.LANG_ID_MAP);
const loadedRegions = {};

const fetchMessage = async (region) => {
  const regionPostfix = region === 'sg' ? '' : `-${region}`;

  try {
    const res = await axios.get(
      `https://rosetta${regionPostfix}.garenanow.com/transify/${process.env.TRANSIFY_ID}?lang=${langIdMap[region]}`,
    );
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`[nuxt-i18n] Fetch i18n failed with status ${res.status}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('[nuxt-i18n] Referring to local translation');
    return message[region];
  }
};

export async function loadLocale(i18n, region) {
  if (!Object.keys(langIdMap).includes(region)) {
    // eslint-disable-next-line no-console
    return console.warn(`[nuxt-i18n] Undefined i18n region: ${region}`);
  }
  if (region in loadedRegions) {
    return i18n.setLocale(region);
  }

  const message = await fetchMessage(region);
  i18n.setLocaleMessage(region, message);
  loadedRegions[region] = true;
  return i18n.setLocale(region);
}
