export default {
  tw: {
    BTN__MAIN_MENU: '回主選單',
    BTN__MENU: '回選單',
    BTN__MORE: '更多資訊',
    BTN__NEXT: '下一頁',
    BTN__NEXT_CHAPTER: '往下一章',
    BTN__PREV: '上一頁',
    BTN__PREV_CHAPTER: '往上一章',
    BTN__SUPPORT: '客服中心',
    CHARACTER__1: '胡小寶',
    CHARACTER__2: '阿暖',
    CHARACTER__3: '趙莞兒',
    CHARACTER__4: '柳扶風',
    CHARACTER__5: '沈孤鴻',
    CHARACTER__6: '唐青團',
    CHARACTER__7: '唐青楓',
    CHARACTER__DESC__1:
      '<p>他沒有卓絕的武功，也沒有高貴的出身，大大咧咧，好像沒心沒肺的樣子。</p><br /><p>然他心裡懷有理想，希望在江湖闖蕩出一些事業，這是大志。他的小志則真的很小，比如救濟無家可歸又飢腸轆轆的乞丐。</p><br /><p>江湖上只有這一個胡小寶，他騙過你的錢，但是他不是個壞人，他牽著狗，同你江湖到處走，是你可愛的朋友。</p>',
    CHARACTER__DESC__2:
      '<p>阿暖的母親是中原人，父親是來自天山魔教的烏高布族人，此族之人擅長馴養猛禽老鷹，阿暖於此更是即有天賦。</p><br /><p>小小年紀，刀法不容小覷。</p>',
    CHARACTER__DESC__3:
      '<p>大宋公主趙莞兒事葉開的記名弟子，雖然出身高貴卻毫無公主架子，行走江湖時他想隱藏自己的身份，通常自稱八荒弟子。</p><p>江湖和朝堂好像格之千里，又如此比鄰而居，牽一髮動全身</p><p>維持兩者間的平衡便是趙莞兒的追求。</p>',
    CHARACTER__DESC__4:
      '<p>弱柳扶風，婀娜婉麗。</p><br /><p>扶風雖有如此溫柔的名字，然性情卻耿直率真，行動風風火火，為人嫉惡如仇。</p>',
    CHARACTER__DESC__5:
      '<p>沈孤鴻，又名呼延鴻，大宋忠孝王的長子，繼任伏龍谷谷主。</p><br /><p>性情穩重內斂，頗有長兄之風。</p><br /><p>出手闊綽，是錢財於身外之物。</p><br /><p>心懷天下，願為萬世開太平，然知天意難測，並不執著於此事。</p>',
    CHARACTER__DESC__6:
      '<p>唐青團是個翩翩公子哥，與他邂逅在江湖或許只是緣分巧合。</p><br /><p>他自稱唐門弟子，周身風流氣韵，言談舉止都很得體，然而行事神祕。</p>',
    CHARACTER__DESC__7:
      '<p>又名唐二，水龍吟盟主，移花宮少宮主。</p><br /><p>紅葉扇隨身，灑脫意隨心，精通唐門之精妙暗器與傀儡之術，又兼有移花武學。</p><br /><p>然江湖大義當頭時，他亦能擔負重任，甚至以命相搏。公子羽之青龍會風波落幕之後，唐青楓組建天峰盟，武林大幕，已換新章。</p>',
    CONTENT__NONE: '暫無內容',
    FEATURE__CHARACTER: '人物介紹',
    FEATURE__CLASS: '門派介紹',
    FEATURE__FEATURE: '遊戲特色',
    FEATURE__ROLE: '身分介紹',
    FEATURE__STORY: '故事介紹',
    GUIDE__CATEGORY__0: '最新',
    NEWS__CATEGORY__0: '全部',
    SCENE__TAB__0: '圖片',
    SCENE__TAB__1: '影片',
    STATUS__COMING_SOON: '敬請期待',
  },
  th: {
    BTN__MAIN_MENU: 'เมนูหลัก',
    BTN__MENU: 'เมนู',
    BTN__MORE: 'เพิ่มเติม',
    BTN__NEXT: 'หน้าถัดไป',
    BTN__NEXT_CHAPTER: 'บทถัดไป',
    BTN__PREV: 'ก่อนหน้า',
    BTN__PREV_CHAPTER: 'บทก่อนหน้า',
    BTN__SUPPORT: 'Customer Service',
    CHARACTER__1: 'โอเสี่ยวป้อ',
    CHARACTER__2: 'อาหนวน',
    CHARACTER__3: 'เตี๋ยเหว่งยี',
    CHARACTER__4: 'ลิ่วฮูฮวง',
    CHARACTER__5: 'ซิ่มโกวฮง',
    CHARACTER__6: 'ตึงแชทวง',
    CHARACTER__7: 'ตึงแชปัง',
    CHARACTER__DESC__1:
      'เขาไม่ใช่คนที่เชี่ยวชาญในศิลปะการต่อสู้ และไม่ได้มาจากตระกูลอันสูงส่งแต่อย่างใด  เป็นคนสะเพร่าและเหมือนไม่สนใจต่อสิ่งใดๆ แต่ยังไงก็ตาม เขามีความฝันที่จะไปสู่จุดที่ยิ่งใหญ่ในยุทธภพ \n\nนอกเหนือจากความทะเยอทะยานนี้แล้วนั้น เขายังมีเป้าหมายในแต่ละวันที่จะช่วยเหลือเหล่าคนเร่ร่อน คนขอทานที่หิวโหย ซึ่งในแผ่นดินยุทธภพแห่งนี้ มีเพียงแค่เขา "โอเสี่ยวป้อ" คนเดียวเท่านั้น \n\nแม้ว่าเขานั้นจะขโมยเงินจากคุณไป แต่เขาก็ไม่ใช่คนเลวร้ายอะไร และเขากับสุนัขคู่ใจสุดน่ารัก ก็พร้อมที่จะเดินทางไปกับคุณ',
    CHARACTER__DESC__2:
      'อาหนวน ผู้ฝึกเหยี่ยวมือดีที่สุดในสำนักดาบเทวะ แม่ของนางเป็นคนในพื้นที่จงหยวน ส่วนพ่อของนางนั้นเป็นคนเผ่าโอวเกาโบ้วจากลัทธิหนึ่งในภูเขาสวรรค์ ซึ่งเป็นเผ่าที่ช่ำชองในด้านศิลปะการฝึกเหยี่ยวเพื่อล่าเหยื่อ \n\nแม้ว่านางจะยังอายุน้อย แต่ว่าความสามารถในวิชาดาบของนางนั้นก็ไม่น้อยหน้าใคร และทุกคนในสำนักดาบเทวะต่างรักและเอ็นดูนาง',
    CHARACTER__DESC__3:
      'เตี๋ยเหว่งยี องค์หญิงแห่งราชวงศ์ซ่ง และเป็นศิษย์คนสนิทของเยี่ยคุย แม้ว่านางนั้นจะกำเนิดในตระกูลอันสูงส่ง แต่นางนั้นก็ไม่ถือตัวในฐานะองค์หญิง และมักจะบอกว่าตนนั้นเป็นหนึ่งในศิษย์ของอาณาจักรทั้งแปดแห่งยุทธภพยามที่นางต้องการจะปิดบังฐานะที่แท้จริง\n\nจริงอยู่ที่ยุทธภพและโถงราชวังนั้นอยู่ห่างไกลกันระยะหลายพันลี้ แต่ที่แท้จริงแล้วกลับอยู่ใกล้กันเพียงนิดเดียว หากมีเรื่องราวอันใดก็สามารถพึงพาอาศัยซึ่งกันได้ นี่เป็นสิ่งที่เตี๋ยเหว่งยีนั้นปรารถนาอันเป็นที่สุด',
    CHARACTER__DESC__4:
      'ศิษย์แห่งสำนักทีเฮียง และเป็นสมาชิกหนึ่งของบ๊วยทั้งเจ็ดในหุบเขารุ่นที่ 2 ชื่อของนางนั้นฟังดูอ่อนโยน นุ่มนวล แต่ตัวตนของนางนั้นเป็นคนที่ตรงไปตรงมา เต็มไปด้วยปณิธานอันแรงกล้า และพร้อมลงโทษกับเรื่องราวอยุติธรรมทุกสิ่งที่นางพบเจอ\n\nแม่และพ่อของนางมีนามว่าอุงบุงและลิ่วเล้ง ขณะที่ทั้งสองได้ทำการจมกระบี่ที่สำนักไท่ไป๋ ก็โชคร้ายถูกพวกโจรภูเขาคีเลียงลอบสังหาร\n\nภายหลังลิ่วฮูฮวงได้รับอุปการะจากคนของสำนักทีเฮียง ผู้อยู่เบื้องหลังอันลึกลับที่คอยสอนวิชายาและวิชาดาบให้แก่เธอ',
    CHARACTER__DESC__5:
      'ซิ่มโกวฮงหรืออีกชื่ออูเหยงฮง เป็นบุตรคนโตของตงหาอ๋องเจ้าชายแห่งราชวงศ์ซ่ง และเป็นผู้สำเร็จในฐานะผู้ปกครองหุบเขามังกร\n\nเขาเป็นคนที่มีความมุ่งมั่นและแน่วแน่ และยังเป็นคนที่ใจดีเปรียบเป็นพี่ชายใหญ่ของทุกคน ในอุดมคติเขานั้น ความมั่งมีมั่งคั่งนั้นเป็นแค่เรื่องเล็กน้อย ความทะเยอทะยานอันยิ่งใหญ่ที่เขามีต่างหากที่จะนำมาสู่ความสงบสุขสู่โลก\n\nแต่เขาเองก็เข้าใจเป็นอย่างดีว่าชะตากรรมนั้นไม่อาจคาดเดาได้ เขาจึงไม่ได้ยึดติดกับปัญหาที่เกิดขึ้นเสียเท่าไหร่',
    CHARACTER__DESC__6:
      'ดอกไม้ผลิบานโอบขุนเขาอันเขียวขจี ชายผู้อ้างตัวว่าเป็นหนึ่งในทายาทตระกูลตึง ผู้ซึ่งคงท่าทีแห่งความสง่างาม\n\nเขายืนพิงต้นไม้พลางหัวเราะเย้ยหยันโลกที่วุ่นวายในยุทธภพ เรื่องราวของเขานั้นช่างลึกลับซับซ้อน แต่ทว่าดูเหมือนเขาจะมีเวลาว่างกว่าใครๆ \n\nตึงแชทวง ทายาทผู้สูงศักดิ์ คนที่คุณจะพบเจอได้ในยามบังเอิญล้วนๆ การกระทำอันลึกลับที่สวนทางกับที่เขาอวดอ้าง ว่าทายาทตระกูลตึงนั้นสง่างามและเป็นผู้มีมารยาทกว่าใคร',
    CHARACTER__DESC__7:
      'ตึงแชปัง หรือบางครั้งจะเรียกเขาในนามว่าตึงหนอ เขาเป็นผู้นำของพรรคมังกรสมุทรคำราม และยังเป็นนายน้อยแห่งตำหนักบุปผา \n\nยามที่มีพัดสีชาดอยู่คู่กาย เขาจะโลดแล่นอย่างไร้ขีดจำกัด เขานั้นเป็นปรมาจารย์ในด้านอาวุธลับและหุ่นเชิดของสำนักตึงมุง รวมถึงเชี่ยวชาญศิลปะการต่อสู้ของของสำนักบุปผาเป็นอย่างดีอีกด้วย\n\nยามที่สถานการณ์นั้นเต็มไปด้วยความเสี่ยง เขานั้นพร้อมที่จะรับภาระอันหนักอึ้ง และเดินหน้าสู้อย่างไม่ย่อถอย หลังจากที่เกิดเรื่องวุ่นวายขององค์ชายอู่และพรรคมังกรครามได้สงบลง ตึงแชปังก็ได้ริเริ่มก่อตั้งพรรคยอดสวรรค์ และได้เปิดตำนานบทใหม่ในโลกของศิลปะแห่งการต่อสู้',
    CONTENT__NONE: 'ไม่มี',
    FEATURE__CHARACTER: 'คำอธิบายตัวละคร',
    FEATURE__CLASS: 'แนะนำสำนัก',
    FEATURE__FEATURE: 'ระบบเกม',
    FEATURE__ROLE: 'คำอธิบายอาชีพ',
    FEATURE__STORY: 'เรื่องราว',
    GUIDE__CATEGORY__0: 'ใหม่',
    NEWS__CATEGORY__0: 'ทั้งหมด',
    SCENE__TAB__0: 'รูปภาพ',
    SCENE__TAB__1: 'วิดีโอ',
    STATUS__COMING_SOON: 'เร็วๆ นี้',
  },
  en: {
    BTN__MAIN_MENU: 'MAIN MENU',
    BTN__MENU: 'MENU',
    BTN__MORE: 'MORE',
    BTN__NEXT: 'NEXT',
    BTN__NEXT_CHAPTER: 'NEXT',
    BTN__PREV: 'PREV',
    BTN__PREV_CHAPTER: 'PREV',
    BTN__SUPPORT: 'SUPPORT',
    CHARACTER__1: 'Hu Siao Bao',
    CHARACTER__2: 'A Nuan',
    CHARACTER__3: 'Jhao Wan Er',
    CHARACTER__4: 'Liou Fu Fong',
    CHARACTER__5: 'Shen Gu Hong',
    CHARACTER__6: 'Tang Ching Tuan',
    CHARACTER__7: 'Tang Ching Fong',
    CHARACTER__DESC__1: '',
    CHARACTER__DESC__2: '',
    CHARACTER__DESC__3: '',
    CHARACTER__DESC__4: '',
    CHARACTER__DESC__5: '',
    CHARACTER__DESC__6: '',
    CHARACTER__DESC__7: '',
    CONTENT__NONE: 'no result',
    FEATURE__CHARACTER: 'CHARACTER',
    FEATURE__CLASS: 'CLASS',
    FEATURE__FEATURE: 'FEATURE',
    FEATURE__ROLE: 'ROLE',
    FEATURE__STORY: 'STORY',
    GUIDE__CATEGORY__0: 'NEW',
    NEWS__CATEGORY__0: 'ALL',
    SCENE__TAB__0: 'IMAGES',
    SCENE__TAB__1: 'VIDEOS',
    STATUS__COMING_SOON: 'Coming soon',
  },
};
