//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import TopBar from '~/components/TopBar';
import Footer from '~/components/Footer';
import TopNav from '~/components/TopNav';
import BottomNav from '~/components/BottomNav';
import Modals from '~/components/Modals';
import FirstEnterLoading from '~/components/FirstEnterLoading';
import utils from '~/utils';

export default {
  middleware: 'checkPreindexLink', // fetch api/config and check preindexLink

  components: {
    TopBar,
    Footer,
    TopNav,
    BottomNav,
    Modals,
    FirstEnterLoading,
  },

  data() {
    return {
      REGION: process.env.REGION,
      ADS_ID: process.env.ADS_ID,
      ADS_LABEL: process.env.ADS_LABEL,
    };
  },

  computed: {
    ...mapState(['isShowFirstEnterLoading', 'isMobile']),
  },

  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize);
    // add pageview tracking
    this.$gtag('event', 'conversion', {
      send_to: `${this.ADS_ID}/${this.ADS_LABEL}`,
    });

    if (this.isShowFirstEnterLoading) {
      setTimeout(() => {
        this.updateIsShowFirstEnterLoading(false);
      }, 2000);
    }
  },

  methods: {
    ...mapActions(['updateIsShowFirstEnterLoading', 'updateIsMobile']),
    handleResize() {
      const ratio = utils.calcRatio();

      // set root font size
      const elem = document.documentElement;
      elem.dataset.ratio = ratio;

      const isMobile = window.innerWidth <= 750;
      if (isMobile !== this.isMobile) {
        this.updateIsMobile(isMobile);
      }
    },
  },
};
