//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constants from '~/constants';
import utils from '~/utils';

export default {
  name: 'TopNav',

  data() {
    return {
      constants,
      CDN: process.env.CDN,
      REGION: process.env.REGION,
      isFixed: false,
      isHover: false,
    };
  },

  computed: {
    ...mapState(['nav']),
  },

  mounted() {
    this.$nextTick(() => {
      this.handleScroll();
    });
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    checkLinkType(link) {
      return utils.linkType(link);
    },
    handleScroll() {
      const topOffset = process.env.REGION === 'th' ? 0 : 43;
      this.isFixed = window.pageYOffset > topOffset;
    },
    handleExternalLinkClick(subNav) {
      if (subNav.isBlank) {
        this.$gtag('event', `navbar_${subNav.title}`);
      }
    },
  },
};
