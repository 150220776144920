//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import Trailer from './modal/Trailer';
import Relationship from './modal/Relationship';

export default {
  name: 'Modals',

  components: {
    Trailer,
    Relationship,
  },

  computed: {
    ...mapState(['currentModal']),
  },
};
