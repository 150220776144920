//
//
//
//
//
//

export default {
  name: 'Footer',

  mounted() {
    window.sitefooter.create({
      game: 'mbm',
      region: process.env.REGION,
    });
  },
};
