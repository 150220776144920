import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0edd5a9c&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=0edd5a9c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0edd5a9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBar: require('/var/www/mbm.garena.all/frontend/components/TopBar.vue').default,Footer: require('/var/www/mbm.garena.all/frontend/components/Footer.vue').default,TopNav: require('/var/www/mbm.garena.all/frontend/components/TopNav.vue').default,BottomNav: require('/var/www/mbm.garena.all/frontend/components/BottomNav.vue').default,Modals: require('/var/www/mbm.garena.all/frontend/components/Modals.vue').default,FirstEnterLoading: require('/var/www/mbm.garena.all/frontend/components/FirstEnterLoading.vue').default})
